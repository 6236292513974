import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/style/anime.css";
import "vue-neat-modal/dist/vue-neat-modal.css";
import "./assets/style/common.less";
import { initTheme } from "@/utils/utils"
import { formatDate } from '@/utils/utils'
import Toaster from "@meforma/vue-toaster";

const app = createApp(App)
app.use(store).use(router).use(Toaster).provide("toast", app.config.globalProperties.$toast).mount('#app')

// 初始化主题
initTheme()
app.config.globalProperties.$formatDate = formatDate;
window["$toast"] = app.config.globalProperties.$toast;