import { createRouter, createWebHistory } from 'vue-router'
const notFountErrorMessages = ['メールでご案内いたしました参加URLを開いてください。']
const notFountErrorMessagesStr = window.btoa(
  encodeURIComponent(JSON.stringify(notFountErrorMessages))
)
const routes = [
  {
    path: "/user/:code",
    name: "EnterCode",
    component: () =>
      import(/* webpackChunkName: "Common" */ "../views/common/EnterCode.vue"),
    meta: {
      publicPage: true,
      shouldLogin: false,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "Common" */ "../views/common/Error.vue"),
    meta: {
      publicPage: true,
      shouldLogin: false,
    },
  },
  // apply
  {
    path: "/apply/:id",
    name: "ApplyForm",
    component: () =>
      import(/* webpackChunkName: "Apply" */ "../views/common/ApplyForm.vue"),
    meta: {
      publicPage: true,
      shouldLogin: false,
    },
  },
  {
    path: "/live",
    component: () =>
      import(/* webpackChunkName: "Live" */ "../views/live/Index.vue"),
    meta: {
      publicPage: false,
      shouldLogin: true,
      pageType: "live",
    },
    children: [
      {
        //默认
        path: "",
        redirect: { name: "LiveEntrance" },
      },
      {
        path: "livepre",
        component: () =>
          import(
            /* webpackChunkName: "Live" */ "../views/live/prePage/Index.vue"
          ),
        children: [
          {
            path: "",
            redirect: { name: "LiveEntrance" },
          },
          {
            path: "live-entrance",
            name: "LiveEntrance",
            component: () =>
              import(
                /* webpackChunkName: "Live" */ "../views/live/prePage/LiveEntrance.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "live",
              haveTheme: true,
            },
          },
        ],
      },
      {
        path: "streaming",
        component: () =>
          import(
            /* webpackChunkName: "Live" */ "../views/live/streamingPage/Index.vue"
          ),
        meta: {
          publicPage: false,
          shouldLogin: true,
          pageType: "live",
          haveTheme: true,
        },
        children: [
          {
            //默认
            path: "",
            redirect: { name: "LiveWait" },
          },
          {
            path: "live-wait",
            name: "LiveWait",
            component: () =>
              import(
                /* webpackChunkName: "Live" */ "../views/live/streamingPage/LiveWait.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "live",
              haveTheme: true,
            },
          },
          {
            path: "live-streaming",
            name: "LiveStreaming",
            component: () =>
              import(
                /* webpackChunkName: "Live" */ "../views/live/streamingPage/LiveStreaming.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "live",
              haveTheme: true,
            },
          },
          {
            path: "live-end",
            name: "LiveEnd",
            component: () =>
              import(
                /* webpackChunkName: "Live" */ "../views/live/endPage/LiveEnd.vue"
              ),
            meta: {
              publicPage: false,
              shouldLogin: true,
              pageType: "live",
              haveTheme: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*", redirect: {
      name: "Error",
      query: {
        errorData: notFountErrorMessagesStr,
      },
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
