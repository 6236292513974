<template>
  <div class="app-bg">
    <router-view />
    <Loading v-if="loadingShow" text="Loading"></Loading>
  </div>
</template>
<script>
import { computed } from 'vue'
import store from '@/store'
import Loading from '@/components/Loading.vue'
export default {
  components: {
    Loading,
  },
  setup() {
    const loadingShow = computed(() => {
      return store.getters.loadingShow
    })
    return { loadingShow }
  },
}
</script>
<style lang="less">
.app-bg {
  background-color: @mainBgColor;
  height: 100%;
}
</style>
