import { createStore } from "vuex";
import { authApi } from "@/api";

export default createStore({
  state: {
    token: localStorage.getItem("token") || "",
    nicnName: "",
    loadingShow: false,
    eventDetail: null,
    jointPhoto: null,
    trtcData: {
      client: null,
      remoteStream: null,
      localStream: null,
      streamingInfo: null,
    },
    eventExpress: "",
    eventId: "",
    serverDelay: 0,
    checkDelayOk: false,
    checkDelayPromise: null,
    nowTime: new Date().getTime(),
    vol: 0,
    networkIsLow: false,
    isBan: false,
    winHeight: 0,
    winWidth: 0,
    trySEFlag: false,
    countStartFlag: false
  },
  getters: {
    token: (state) => state.token,
    loadingShow: (state) => state.loadingShow,
    eventDetail: (state) => state.eventDetail,
    jointPhoto: (state) => state.jointPhoto,
    trtcData: (state) => state.trtcData,
    eventExpress: (state) => state.eventExpress,
    eventId: (state) => state.eventId,
    serverDelay: (state) => state.serverDelay,
    checkDelayOk: (state) => state.checkDelayOk,
    checkDelayPromise: (state) => state.checkDelayPromise,
    nowTime: (state) => state.nowTime,
    vol: (state) => state.vol,
    networkIsLow: (state) => state.networkIsLow,
    isBan: (state) => state.isBan,
    winHeight: (state) => state.winHeight,
    winWidth: (state) => state.winWidth,
    countStartFlag: (state) => state.countStartFlag,
  },
  mutations: {
    setIsBan (state, data) {
      state.isBan = data;
    },
    setNetworkIsLow (state, data) {
      state.networkIsLow = data;
    },
    setVol (state, data) {
      state.vol = data;
    },
    setNowTime (state) {
      state.nowTime = new Date().getTime() - state.serverDelay;
    },
    setToken (state, data) {
      state.token = data;
    },
    setLoadingShow (state, data) {
      state.loadingShow = data;
    },
    setEventDetail (state, data) {
      if (data) {
        // startTime 和 endTime 加上日本时区的字符串
        data.startTime = data.startTime + "+09:00";
        data.endTime = data.endTime + "+09:00";
      }
      console.log(data);
      state.eventDetail = data;
    },
    setJointPhoto (state, data) {
      state.jointPhoto = data;
    },
    setTrtcData (state, data) {
      state.trtcData[data.key] = data.value;
    },
    setNickName (state, data) {
      state.nicnName = data;
    },
    setEventExpress (state, data) {
      state.eventExpress = data;
    },
    setEventId (state, data) {
      state.eventId = data;
    },
    setLoading (state, data) {
      state.loadingShow = data;
    },
    sendLog (state, data) {
    },
    setWinHeight (state, data) {
      state.winHeight = data;
    },
    setWinWidth (state, data) {
      state.winWidth = data;
    },
    setCountStartFlag (state, data) {
      state.countStartFlag = data;
    },
    async checkDelay (state) {

    },
    trySE (state) {

    },
  },
  actions: {
    setIsBan ({ commit }, data) {
      commit("setIsBan", data);
    },
    setNetworkIsLow ({ commit }, data) {
      commit("setNetworkIsLow", data);
    },
    setVol ({ commit }, data) {
      commit("setVol", data);
    },
    setNowTime ({ commit }) {
      commit("setNowTime");
    },
    setToken ({ commit }, data) {
      commit("setToken", data);
    },
    setLoadingShow ({ commit }, data) {
      commit("setLoadingShow", data);
    },
    setEventDetail ({ commit }, data) {
      commit("setEventDetail", data);
    },
    setJointPhoto ({ commit }, data) {
      commit("setJointPhoto", data);
    },
    setTrtcData ({ commit }, data) {
      commit("setTrtcData", data);
    },
    setNickName ({ commit }, data) {
      commit("setNickName", data);
    },
    setEventExpress ({ commit }, data) {
      commit("setEventExpress", data);
    },
    setLoading ({ commit }, data) {
      commit("setLoading", data);
    },
    setEventId ({ commit }, data) {
      commit("setEventId", data);
    },
    setWinHeight ({ commit }, data) {
      commit("setWinHeight", data);
    },
    setWinWidth ({ commit }, data) {
      commit("setWinWidth", data);
    },
    setCountStartFlag ({ commit }, data) {
      commit("setCountStartFlag", data);
    },
    sendLog ({ commit }, data) {
      commit("sendLog", data);
    },
    checkDelay ({ commit }) {
      // debugger
      commit("checkDelay");
    },
    trySE ({ commit }) {
      commit("trySE");
    },
  },
  modules: {},
});
