import { createAPI } from './create-api'
import auth from './module/auth'
import { showLoading, hideLoading, getLang } from "../utils/utils";
import store from "../store";
import router from "@/router";

const api = createAPI({ baseURL: '/api/user' })

//请求拦截器
api.interceptors.request.use(
    (config) => {
        const noLoading = config.noLoading;
        console.log(config);
        if (!noLoading) {
            showLoading();
        }
        return config;
    },
    (error) => {
        const config = error.response?.config || {};
        const noLoading = config.noLoading;
        if (!noLoading) {
            hideLoading();
        }
        return Promise.reject(error);
    }
);
api.interceptors.response.use(
    (response) => {
        const config = response.config;
        const noLoading = config.noLoading;
        if (!noLoading) {
            hideLoading();
        }
        return response;
    },
    (error) => {
        const config = error.response?.config || {};
        const noLoading = config.noLoading;
        if (!noLoading) {
            hideLoading();
        }
        const status = error?.response?.status;
        let messages = ''
        switch (status) {
            case 400:
                messages = error.response.data || [];
                messages.forEach((item) => {
                    window.$toast.error(item, {
                        position: "top",
                    });
                });
                break;
            case 401:
                messages = window.btoa(
                    encodeURIComponent(JSON.stringify(['セッションが切れました。']))
                  )
                  router.replace({
                    name: 'Error',
                    query: {
                      errorData: messages,
                    },
                  })
                break
            case 403:
                messages = window.btoa(
                    encodeURIComponent(JSON.stringify(['Forbidden']))
                  )
                  router.replace({
                    name: 'Error',
                    query: {
                      errorData: messages,
                    },
                  })
                break
            case 404:
                messages = window.btoa(
                    encodeURIComponent(JSON.stringify(['Not Found']))
                  )
                  router.replace({
                    name: 'Error',
                    query: {
                      errorData: messages,
                    },
                  })
                break;
            case 500:
            default:
                messages = window.btoa(
                    encodeURIComponent(JSON.stringify(['予期せぬエラーが発生しました']))
                  )
                  router.replace({
                    name: 'Error',
                    query: {
                      errorData: messages,
                    },
                  })
                break
        }
        return Promise.reject(error);
    }
);
export const authApi = auth(api);

