export default function (api) {
  return {
    login (data) {
      return api.post('/login', {}, { params: data })
    },
    getLiveRoom () {
      return api.get('/live/room')
    },
    getLiveLiveinfo () {
      return api.get('/live/liveinfo')
    },
    getApply (roomId) {
      return api.get(`/apply/${roomId}`)
    },
    postApply (data, roomId) {
      return api.post(`/apply/${roomId}`, data)
    },
    postLiveMsg (data) {
      return api.post('/live/msg', data)
    },
    getLiveMsg () {
      return api.get('/live/msg')
    },
    getLiveUnsubscribe () {
      return api.get('/live/unsubscribe')
    }
  }
}
