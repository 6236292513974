let loadingCount = 0
let loadingTimer = null
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import initThemeData from "@/utils/defaultTheme.json"

const startLoading = () => {
    store.dispatch('setLoading', true)
}

const endLoading = () => {
    store.dispatch('setLoading', false)
}

export const showLoading = () => {
    clearTimeout(loadingTimer)
    loadingTimer = setTimeout(() => {
        loadingCount = 1
        hideLoading()
    }, 5000)
    if (loadingCount === 0) {
        startLoading()
    }
    loadingCount += 1
}

export const hideLoading = () => {
    if (loadingCount <= 0) {
        return
    }
    loadingCount -= 1
    if (loadingCount === 0) {
        clearTimeout(loadingTimer)
        endLoading()
    }
}
export const nicnNameSubstring = (nickName) => {
    if (!nickName) {
        return "";
    }
    return nickName.substring(0, 2);
};
export const creatColorByNickName = (NickName) => {
    if (!NickName) {
        return "#000000";
    }
    // nick name to code
    let id = 0;
    for (var i = 0; i < NickName.length; i++) {
        id += NickName.charCodeAt(i);
    }
    id = id % 100;
    const h = id * (360 / 100);
    const s = 80;
    const l = 30;
    return hslToHex(h, s, l);
};
export const hslToHex = (h, s, l) => {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, "0"); // convert to Hex and prefix "0" if needed
    };
    return `${f(0)}${f(8)}${f(4)}`;
};

export const isDuringDate = (beginDateOri, endDateOri) => {
    //
    let curDate = new Date().getTime() - store.getters.serverDelay
    let beginDate = new Date(beginDateOri).getTime()
    let endDate = new Date(endDateOri).getTime();
    if (curDate >= beginDate && curDate < endDate) {
        return true;
    }
    return false;
};

export const initEventData = () => {
    store.dispatch("setEventDetail", null);
    store.dispatch("setEventExpress", "");
    store.dispatch("setEventId", "");

    store.dispatch("setJointPhoto", null);
    store.dispatch("setIsBan", false);


    store.dispatch("setTrtcData", {
        key: "client",
        value: null,
    });
    store.dispatch("setTrtcData", {
        key: "remoteStream",
        value: null,
    });
    store.dispatch("setTrtcData", {
        key: "localStream",
        value: null,
    });
    store.dispatch("setTrtcData", {
        key: "streamingInfo",
        value: null,
    });

    localStorage.removeItem("eventType");
}


export const setTheme = (themeData) => {
    Object.keys(themeData).forEach(key => {
        document.body.style.setProperty(`--${key}`, themeData[key])
    })
}

export const initTheme = () => {
    setTheme(initThemeData)
}

export const langList = [
    {
        label: '日本語',
        code: 'ja',
        subtitle: 'ja-JP',
        azureSpeech: {
            translationLanguage: 'ja',
            recognitionLanguage: 'ja-JP'
        }
    },
    {
        label: '中文',
        code: 'zh',
        subtitle: 'zh-CN',
        azureSpeech: {
            translationLanguage: 'zh-Hans',
            recognitionLanguage: 'zh-CN'
        }
    },
    {
        label: '한국인',
        code: 'ko',
        subtitle: 'ko-KR',
        azureSpeech: {
            translationLanguage: 'ko',
            recognitionLanguage: 'ko-KR'
        }
    },
    {
        label: 'English',
        code: 'en',
        subtitle: 'en-US',
        azureSpeech: {
            translationLanguage: 'en',
            recognitionLanguage: 'en-US'
        }
    }
]

// https://www.techonthenet.com/js/language_tags.php
export const getLang = () => {
    const cacheLocale = localStorage.getItem('locale')
    let lang = navigator.language || navigator.userLanguage
    if (cacheLocale) {
        lang = cacheLocale
    } else {
        lang = lang.substr(0, 2)
    }
    const data = langList.find((item) => {
        return item.code === lang
    })
    if (!data) {
        lang = 'en'
    }
    return lang
}

export const formatDate = (date_, format, utc) => {
    const date = new Date(date_)
    var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    function ii (i, len) { var s = i + ""; len = len || 2; while (s.length < len) s = "0" + s; return s; }

    var y = utc ? date.getUTCFullYear() : date.getFullYear();
    format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
    format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
    format = format.replace(/(^|[^\\])y/g, "$1" + y);

    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
    format = format.replace(/(^|[^\\])M/g, "$1" + M);

    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
    format = format.replace(/(^|[^\\])d/g, "$1" + d);

    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
    format = format.replace(/(^|[^\\])H/g, "$1" + H);

    var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
    format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
    format = format.replace(/(^|[^\\])h/g, "$1" + h);

    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
    format = format.replace(/(^|[^\\])m/g, "$1" + m);

    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
    format = format.replace(/(^|[^\\])s/g, "$1" + s);

    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])f/g, "$1" + f);

    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
    format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

    var t = T.toLowerCase();
    format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
    format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
        tz = Math.abs(tz);
        var tzHrs = Math.floor(tz / 60);
        var tzMin = tz % 60;
        K += ii(tzHrs) + ":" + ii(tzMin);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);

    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

    format = format.replace(/\\(.)/g, "$1");

    return format;
};